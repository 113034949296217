body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-part {
  text-align: left;
  /* color: #594011; */
  color: #004C38;
  padding: 2px 0 2px 2%;
  font-size: 20px;
  margin: 5px 0 15px 5px;
  /* color: #49908e; */
}

.SubPartColored {
  /* background-color: #edf2ee; /*Ugly AF */
  /* background-color: #f8f9fa; */
  background-color: rgba(248, 249, 250, 0.8);
  width: 90vw;
  padding: 20px;
  margin: 15px 5vw 0 5vw;
  border-radius: 0.5em;
  overflow: hidden;
}

.SubPartColored2 {
  /* background-color: #edf2ee; /*Ugly AF */
  /* background-color: #daeafa; */
  /* background-color: rgb(218, 234, 250, 0.8); */
  width: 90vw;
  padding: 20px;
  margin: 15px 5vw 0 5vw;
  border-radius: 0.5em;
}

.bold {
  font-weight: bold;
}


.clickableItem {
  font: bold;
  color: black;
  text-decoration: none;
}

.clickableItem:hover {
  font: bold;
  color: rgb(78, 76, 76);
  text-decoration: none;
}

.InfoMenu {
  display: flex;
  flex-direction: column;
}

.subPartInfoLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subpart-info-gray {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 2vw 10px 2vw;
  background-color: #E7E6E6;
  width: 45vw;
  height: 20vh;
  color: #004C38;
  border-radius: 0.5em;
  box-shadow: 5px 5px 10px #a4a4a4,
    -5px -5px 10px #ffffff;
}

.subpart-info-green {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 2vw 10px 2vw;
  background-color: #004C38;
  width: 45vw;
  height: 20vh;
  color: #E7E6E6;
  border-radius: 0.5em;
  box-shadow: 5px 5px 10px #a4a4a4,
    -5px -5px 10px #ffffff;
}

.subpart-info-gray-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 3vw 10px 3vw;
  background-color: #E7E6E6;
  width: 94vw;
  height: auto;
  color: #004C38;
  border-radius: 0.5em;
  min-height: 20vh;
  box-shadow: 5px 5px 10px #a4a4a4,
    -5px -5px 10px #ffffff;
}

.InfoIconGreen {
  font-size: 25px;
  /* width: 500px; */
  color: #004C38;
}

.InfoIconGray {
  font-size: 25px;
  /* width: 500px; */
  color: #E7E6E6;
}

.flexEquipe {
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  .flexEquipe {
    display: flex;
    flex-direction: row;
    font-size: small;
  }

  .InfoContent {
    font-size: 12px;
  }
}

.InfoContentEquipe {
  color: gray;
}

@media (max-width: 600px) {

  .InfoContentEquipe {
    font-size: 10px;
    color: gray;
  }
}