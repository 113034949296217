.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg {
  /* background-image: url("images/backgroundDark.svg"); */
  background-image: url("images/background_gray.svg");
  margin-top: calc(20vh - 85px);
  margin-bottom: calc(20vh - 85px);
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-homepage {
  /* background-image: url("images/backgroundDark.svg"); */
  background-image: url("images/homepage/calculator-g0308dc4e4_1920.jpg");
  margin-top: calc(20vh - 85px);
  margin-bottom: calc(20vh - 60px);
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  position: relative;
}

.txt-homepage {
  font-size: 25px;
  position: absolute;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  color: white;
  width: 60vw;
  left: 3px;
}

@media (max-width: 850px) {
  .txt-homepage {
    font-size: 20px;
    position: absolute;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
    width: 60vw;
    left: 3px;
  }
}

@media (max-width: 650px) {
  .txt-homepage {
    font-size: 15px;
    position: absolute;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
    width: 60vw;
    left: 3px;
  }
}

/* goJs */
.diagram-component {
  position: relative;
  /* max-width: calc(100vw - 30px); */
  max-width: 100%;
  margin: 0px;
  /* overflow: hidden; */
  background-color: white;
  border: 0cm;
}

.body {
  margin: 0 4% 0 4%;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.subpart {
  height: 100vh;
  min-height: 100vh;
  margin-top: 100px;
  overflow: auto;
}

.subpart-bottom {
  height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  margin-top: 100px;
}


.footer {
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}