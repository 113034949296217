.container-card-equipe {
    display: flex;
    flex-direction: row;
    text-align: left;
    width: 60vw;
    justify-content: space-between;
    background-color: rgb(248, 249, 250);
    /* padding: 2%; */
    box-shadow: 5px 5px 10px #a4a4a4,
        -5px -5px 10px #ffffff;
    border-radius: 5%;
}

.arrow-card-equipe{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vw;
}

.container-photo-equipe {
    width: 30vw;
    position: relative;
    top: -10vw;
}

.ext-photo-equipe {
    width: 17vw;
    border-radius: 5%;
    background-color: rgb(248, 249, 250);
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
}

.photo-equipe {
    width: 15vw;
    margin: 1vw;
    /* border-radius: 100%; */
    /* box-shadow:  22px 22px 39px #a8a8a8,
    -22px -22px 39px #ffffff */
    /* box-shadow: 22px 22px 57px #989898,
    -22px -22px 57px #ffffff */
    border-radius: 5%;
}

.button-equipe {
    padding: 2px 5px;
    background: #efefef;
    border: none;
    border-radius: .5rem;
    color: #444;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
        -6px -6px 10px rgba(255, 255, 255, .5),
        6px 6px 8px rgba(255, 255, 255, .075),
        6px 6px 10px rgba(0, 0, 0, .15);
}

.button-equipe-selected {
    padding: 2px 5px;
    background: #efefef;
    border: none;
    border-radius: .5rem;
    color: #444;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
        -2px -2px 4px rgba(255, 255, 255, .4),
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
}

.button-equipe:hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
        -2px -2px 4px rgba(255, 255, 255, .4),
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
}


.button-equipe-content {
    width: 30px;
    height: 30px;
    margin-top: 5px;
}

.button-equipe-content-selected {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    color: gray;
}

.card-container-top {
    display: flex;
    flex-direction: row;
    height: 20vh;
}

@media (max-width: 800px) {
    .container-photo-equipe {
        width: 30vw;
        position: relative;
        top: -10vw;
        left: 1vw;
    }

    .ext-photo-equipe {
        width: 15vw;
        border-radius: 5%;
        background-color: rgb(248, 249, 250);
        box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
    }

    .photo-equipe {
        width: 13vw;
        margin: 1vw;
        border-radius: 5%;
    }

    .card-container-top {
        display: flex;
        flex-direction: row;
        height: 10vh;
    }
}
