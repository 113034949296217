.subMenu {
    background-color: rgb(248, 249, 250, 0.5);
    padding-left: 2%;
    padding: 10px 0 0 10px;
    margin: 2px 0 2px 0;
    width: 60vw;
    max-width: 700px;
    height: 70vh;
    border-radius: 5%;
    box-shadow: 5px 5px 10px #a4a4a4,
        -5px -5px 10px #ffffff;
}

.subMenu-404 {
    background-color: rgb(248, 249, 250, 0.5);
    padding-left: 2%;
    padding: 10px 0 0 10px;
    margin: 2px 0 2px 0;
    width: 60vw;
    max-width: 700px;
    height: 50vh;
    border-radius: 5%;
    box-shadow: 5px 5px 10px #a4a4a4,
        -5px -5px 10px #ffffff;
}

.subMenu-title {
    color: rgb(0, 76, 56);
    font-weight: bold;
    margin: 10px;
    font-size: 1.2em;
}

.list-title {
    color: rgb(0, 76, 56);
    font-weight: bold;
}

.list-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.submenu-icon {
    font-size: 20px;
    width: 40px;
    color: rgb(0, 76, 56, 0.5);
    margin: 0 4px 0 0;

}

.submenu-content {
    width: 100%;
    height: 100%;
    margin-right: 3px;
    overflow-x: hidden;
    overflow-y: auto;
}

@media(max-width: 1000px) {
    .subMenu {
        background-color: rgb(248, 249, 250, 0.5);
        padding-left: 2%;
        padding: 10px 0 0 10px;
        margin: 2px 0 2px 0;
        width: 80vw;
        max-width: 700px;
        height: 70vh;
        border-radius: 5%;
        box-shadow: 5px 5px 10px #a4a4a4,
            -5px -5px 10px #ffffff;
    }

    .submenu-content {
        width: 100%;
        height: 100%;
        margin-right: 3px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

/* @media(min-width: 1000px) {
    .submenu-content {
        width: "1000px";
        margin-right: 5vw;
        height: 50vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
} */

/* test */
.slider {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.slides {
    width: 100%;
    overflow: hidden;
}