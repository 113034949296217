.newsletter-description {
    font-size: 14px;
    margin: 5px 3px 15px 3px;
}

.image-confetti {
    width: 40%;
    height: auto;
    position: absolute;
    right: 0;
    bottom: 20vh;
    rotate: 53deg;
    opacity: 0.5;
}


.newsletter-unsuscribe {
    background-color: rgb(248, 249, 250, 0.5);
    padding-left: 2%;
    padding: 10px 0 0 10px;
    margin-top: calc(15vh - 93px);
    margin-bottom: calc(15vh - 50px);
    width: 60vw;
    max-width: 700px;
    height: 70vh;
    box-shadow: 5px 5px 10px #a4a4a4,
        -5px -5px 10px #ffffff;
    overflow: auto;
}