/* OrgChart.css */
.diagram-div {
  background-color: white;
  box-shadow: 1px 1px #888888;
  border-radius: 5% 3% 3% 3%;
  box-shadow: inset 2px 2px 5px #b8b9be,
    inset -3px -3px 7px #fff !important;
  display: flex;
  flex-direction: column;
  width: 62vw;
  height: 80%;
  margin: 0 20vw 5vw 20vw;
  padding: 5px;
}

.diagram-component {
  left: 2px;
  padding: 5vw;
  width: 60vw;
  margin: auto;
}

@media (min-width: 1500px) {

  .diagram-component {
    left: 2px;
    padding: 5vw;
    width: 55vw;
    margin: auto;
  }
}

.diagram-legend-list {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}