.subMenu-histoire {
    background-color: rgb(248, 249, 250, 0.5);
    padding-left: 2%;
    padding: 10px 0 0 10px;
    margin: 2px 0 2px 0;
    width: 60vw;
    max-width: 700px;
    height: 70vh;
    box-shadow: 5px 5px 10px #a4a4a4,
        -5px -5px 10px #ffffff;
}

.icon-menu-histoire {
    font-size: 20px;
    width: 20px;
    color: rgb(0, 76, 56, 0.5);
    margin: 0 4px 7px 0;

}

.list-title-histoire {
    color: rgb(0, 76, 56);
    font-weight: bold;
    width: calc(20vw - 24px);
    margin: 10px;

    /* flex-grow: 1; */
}

.list-item-histoire {
    display: flex;
    justify-content: flex-start;
}

@keyframes fade-in {
    0% {
        transform: scale(0, .025);
    }

    50% {
        transform: scale(1, .025);
    }
}
