.map-image {
    width: 90%;
    margin: 25px 0;
    background: linear-gradient(145deg, #cacaca, #f0f0f0);
    box-shadow: 20px 20px 60px #bebebe,
        -20px -20px 60px #ffffff;
}

.map-cross{
    position:absolute;
    top: 30px;
    left: 7%;
    cursor: pointer; 
}