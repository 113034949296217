.formation-title {
  text-align: center;
  margin: 1% 0 1% 0;
}

input[type="text"].formation-body {
  margin: 2%;
  border-right: "10px solid" + text;

}

.formation-submenu {
  margin: 2% 2% 5% 2%;
}


.formation-submenu-title {
  margin: 15px;
}


.buttons-sticky {
  position: sticky;
  top: 0px;
  background-color: white;
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

.formation-pres-card {
  background-color: white;
  box-shadow: 1px 1px #888888;
  border-radius: 5% 3% 3% 3%;
  box-shadow: inset 2px 2px 5px #b8b9be,
    inset -3px -3px 7px #fff !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: 62vw; */
  height: 80%;
  margin: 0 0 5vw 0;
  padding: 5px;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 150px;
}

.menu>li {
  margin: 0;

  background-color: white;
}

.menu>li:hover {
  background-color: lightgray;
}

.menu>li>button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.button-panel-formation {
  padding: 2px 5px;
  background: #efefef;
  border: none;
  border-radius: .5rem;
  color: #444;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .2rem;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
    -6px -6px 10px rgba(255, 255, 255, .5),
    6px 6px 8px rgba(255, 255, 255, .075),
    6px 6px 10px rgba(0, 0, 0, .15);
  height: 40px;
}